import React from "react"
import HorizontalScroll from "react-scroll-horizontal"
import { Heading } from "theme-ui"
import clientImages from "./ClientImages"
import "./clients.css"

const styles = {
  heading: {
    textAlign: "center"
  },
}

const Clients = () => {
  return (
    <div className="clients-wrapper">
      <Heading as="h2" style={styles.heading}>
        <span className="clients-title-underline">Our Clients</span>
      </Heading>
      <div className="clients-list-wrapper">
        <HorizontalScroll reverseScroll={true}>
          {clientImages.map((imageSrc, index) => {
            return (
              <div className="image-wrapper" key={`client-${index}`}>
                <img src={imageSrc} alt="Sponsor logo" />
              </div>
            )
          })}
        </HorizontalScroll>
      </div>
    </div>
  )
}

export default Clients
