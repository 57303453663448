import React from 'react';
import { Heading } from 'theme-ui';
import CardList from '@components/CardList';
import './styles.css';

const posts = [{
    id: 'solution-1',
    title: 'Low-Code Platforms',
    excerpt: 'Platforms like Quickbase allow businesses to automate 5x faster than traditional solutions and orchestrate workflows between humans, bots and Artificial Intelligence (AI) solutions.'
}, {
    id: 'solution-2',
    title: 'Robotic Process Automation (RPA)',
    excerpt: 'RPA bots use the existing UI layer of applications to rapidly automate repeatable business processes and routinely provide companies a positive ROI within 12 months of deployment.'
}, {
    id: 'solution-3',
    title: 'Artificial Intelligence (AI)',
    excerpt: 'AI technologies such as intelligent Optical Character Recognition (OCR) solutions provide cognitive capabilities that allow companies to automate 4x more than otherwise possible.'
}]

const styles = {
    heading: {
        textAlign: 'center',
        color: 'omegaDark'
    }
}

const Solutions = () => {
    return (<div className="solutions-info">
        <Heading as="h3" sx={styles.heading}>How We Deliver Intelligent Automation</Heading>
        <CardList nodes={posts}
            variant={['vertical']}
columns={[1, 2, 3, 3]} />
    </div>)
}

export default Solutions;
