import React, { useState } from "react"
import useInterval from "../../../hooks/useInterval"
import { Heading } from "theme-ui"
import VisibilitySensor from "react-visibility-sensor";
import InfoItem from './InfoItem'
import './infos.css'

let posts = [
  {
    title: "Years of Experience",
    value: 0,
    maxValue: 15,
  },
  {
    title: "Global Customers",
    value: 0,
    maxValue: 480,
  },
  {
    title: "Cloud-To-Cloud Integrations",
    value: 0,
    maxValue: 531,
  },
  {
    title: "Total Engagements",
    maxValue: 2442,
  },
  {
    title: "Industries Served",
    value: 0,
    maxValue: 40,
    postSymbol: "+",
  },
]

const styles = {
  heading: {
    color: "white",
    textAlign: "center",
    fontSize: '24px'
  },
}

const Infos = ({ intervalDuration, intervalCount }) => {
  const [isShown, setIsShown] = useState(false)
  const [duration] = useState(intervalDuration || 100)
  const [count] = useState(intervalCount || 20)
  const [tickData, setTickData] = useState({
    tick: 1,
    postsValues: posts.map(post => 0),
  })
  useInterval(
    () => {
      const postsValues = posts.map(post => {
        return tickData.tick === count
          ? post.maxValue
          : Math.round(post.maxValue / count) * tickData.tick
      })
      setTickData({
        tick: tickData.tick + 1,
        postsValues,
      })
      return () => count === tickData.tick
    },
    isShown,
    duration
  )
  return (
    <div className="companies-wrapper">
      <Heading as="h2" style={styles.heading}>
        Leading Companies Trust The Data Collaborative
      </Heading>
      <VisibilitySensor
        onChange={visible => {
          if (visible && !isShown) {
            setIsShown(visible)
          }
        }}
      >
        <div className="companies">
          {posts.map((post, index) => (
            <InfoItem
              key={"company-" + index}
              {...post}
              value={tickData.postsValues[index]}
            />
          ))}
        </div>
      </VisibilitySensor>
    </div>
  )
}

export default Infos
