import React from 'react'
import { Box } from 'theme-ui'

const Divider = ({ space, spacePx, line }) => {
  const margin = spacePx ? `${spacePx}px` : space;
  return (
    <Box
      sx={{
        minWidth: `auto`,
        borderTopStyle: `solid`,
        borderTopColor: line ? `omegaLighter` : `transparent`,
        borderTopWidth: 2,
        height: 0,
        my: [margin, margin]
      }}
    />
  )
}

export default Divider

Divider.defaultProps = {
  space: 4,
  line: false
}
