import React from "react"
import Section from "@components/Section"
import { Heading } from "theme-ui"
import CardList from "@components/CardList"

const cfoTitle = "Solve your toughest business challenges"

const styles = {
  heading: {
    textAlign: "center",
  },
  section: {
    marginTop: "44px",
  },
}

const posts = [
  {
    id: "cfo-1",
    title: "Quickbase Services",
    excerpt:
      "Hundreds of organizations rely on DC for our Quickbase services and solutions because of our expertise and customer- centric mentality. From hourly support to App modernization – we’re here for you.",
    to: "/",
  },
  {
    id: "cfo-2",
    title: "DC Cloud Solutions",
    excerpt:
      "Faster, hosted platform-to-platform integrations will allow your Quickbase or Low-code platform to connect with any modern cloud solution from FedEx tracking to SMS notifications. Always on - around the clock.",
    to: "/",
  },
  {
    id: "cfo-3",
    title: "Custom Web and App Dev",
    excerpt:
      "Top companies trust us with their digital product design and development. We specialize in websites and cross-platform apps for mobile devices – and are also experts in back-end APIs and data to make it all work.",
    to: "/",
  },
  {
    id: "cfo-4",
    title: "Cloud Solutions and Services",
    excerpt:
      "Our remote network administration services and cloud workspaces provide 10x faster processing and 99.97% uptime, across 17 data centers. Whether HIPAA, PCI, Finra, or FedRAMP - we meet or exceed requirements.",
    to: "/",
  },
  {
    id: "cfo-5",
    title: "RPA Services",
    excerpt:
      "Our certified Robotic Process Automation (RPA) developers are expert certified on various platforms and utilize bots to automate processes and exchanges data with legacy, on-premise software using the UI layer.",
    to: "/",
  },
  {
    id: "cfo-6",
    title: "OCR Solutions and Services",
    excerpt:
      "Intelligent Optical Character Recognition (OCR) solutions have matured in the marketplace and are easily integrated into workflows to identify and extract data from digital forms (ID cards photos, PDFs, etc).",
    to: "/",
  },
]

const CFO = () => {
  return (
    <Section>
      <div style={styles.section}>
        <Heading as="h2" style={styles.heading}>
          {cfoTitle}
        </Heading>
        <CardList
          nodes={posts}
          variant={["horizontal-aside", "vertical"]}
          columns={[1, 2, 3, 3]}
        />
      </div>
    </Section>
  )
}

export default CFO
