import React from "react"
import "./info-item.css"

const Company = ({ title, value, maxValue, postSymbol }) => {
  return (
    <div className="company-wrapper">
      <div className="value-handler">
        <span>
          {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          {!!postSymbol && value === maxValue ? postSymbol : ""}
        </span>
      </div>
      <div className="title-handler">
        <span>{title}</span>
      </div>
    </div>
  )
}

export default Company
