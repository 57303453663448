import React from "react"
import { Box, Container } from "theme-ui"
import Divider from "@components/Divider"
import Banner from "../components/Home/Banner/Banner"
import Solutions from "../components/Home/Solutions/Solutions"
import Infos from "../components/Home/CompanyInfos/Infos"
import Clients from "../components/Home/Clients/Clients"
import CFO from "../components/Home/CFO/CFO"
import Contacts from "../components/Home/Contacts/Contacts"

const styles = {
  wrapper: {
    position: `relative`,
  },
  container: {
    position: `relative`,
  },
}

export default function () {
  return (
    <Box sx={styles.wrapper}>
      <Banner />
      <Container variant="compact" sx={styles.container}>
        <Divider spacePx={15} />
        <Solutions />
        <Divider spacePx={15} />
        <Infos />
        <Divider spacePx={15} />
        <Clients />
        <Divider spacePx={15} />
        <CFO />
        <Divider spacePx={15} />
        <Contacts />
        <Divider spacePx={15} />
      </Container>
    </Box>
  )
}
