import React from 'react';
import { Heading } from 'theme-ui';
import ContactForm from '@components/ContactForm';
import Section from "@components/Section";

import './styles.css';

const styles = {
  heading: {
    textAlign: "center",
  },
}


const Contacts = () => {
  return (
    <Section>
      <div className="contact-section">
        <div className="contact-text">
          <p>
            Ten years ago, businesses provided employees with better technology than consumers had available.
            Now businesses are weighted down by monolith systems that require million-dollar
            investments and 9 month cycles to change.
          </p>
          <p>
            Low-code apps, robotic automation, and intelligent automation tools deliver modern,
            microservices and API gateway-based solutions which will solve complex business problems in weeks.
          </p>
          <p>
            Data Collaborative is driven by the success of our clients.
            We strive to build deep relationships and delight our clients.
            Reach out and see what’s possible. We’d love to hear from you.
          </p>
        </div>

        <div className="contact-wrapper">
          <Heading as="h2" style={styles.heading}>
            Let’s talk about how we can help
          </Heading>
          <ContactForm />
        </div>
      </div>
    </Section>
  )
}

export default Contacts;
