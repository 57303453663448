import React from "react"
import { Container } from "theme-ui"
import { Text, Heading } from "theme-ui"

import "./styles.css"

const Banner = () => (
  <div className="banner-wrapper">
    <Container variant="compact">
      <div className="banner-content">
        <div className="content-item heading-section">
          <Heading as="h2">
            <Text>
              The Most Awarded Quickbase
            </Text>
            <Text>
              Service Provider
            </Text>
          </Heading>
        </div>
        <div className="content-item banner-book-consulation">
          <Heading as="h2" style={{color: 'white', borderBottom: 'solid white'}}>
            Full Stack Automation Services and Solutions
          </Heading>
          <Text color="white" className="item-text">
            We automate processes using low-code apps and
          </Text>
          <Text color="white" className="item-text">
            integrated cloud solutions
          </Text>
          <button
            type="button"
            onClick={() => (document.location.href += "contact")}
            className="consult-btn"
          >
            <span className="btn-text">Learn More</span>
            <span class="arrow-icon" />
          </button>
        </div>
      </div>
    </Container>
  </div>
)

export default Banner
